import {IUser} from '@wix/native-components-infra/dist/src/types/types'
import {logout as memberLogout, promptLogin as memberPromptLogin, getCurrentUser} from '../services/member'
import {createAsyncAction} from '../services/redux-toolkit'

export const promptLogin = createAsyncAction<{user: Partial<IUser>; imageUrl: string} | null>(
  'PROMPT_LOGIN',
  async (
    _,
    {
      extra: {
        api,
        controllerParams: {
          flowAPI: {
            environment: {language},
          },
          controllerConfig: {wixCodeApi},
        },
      },
    },
  ) => {
    const user = await memberPromptLogin(wixCodeApi, language)

    if (!user) {
      return null
    }

    const {imageUrl} = await api.getUserProfileImage(user.id)

    return {user: getSerializableUser(user), imageUrl}
  },
)

export const logout = createAsyncAction(
  'LOGOUT',
  async (
    _,
    {
      extra: {
        controllerParams: {
          controllerConfig: {wixCodeApi},
        },
      },
    },
  ) => {
    memberLogout(wixCodeApi)
  },
)

export const loadCurrentUser = createAsyncAction<{user: Partial<IUser>; imageUrl: string} | null>(
  'GET_CURRENT_USER',
  async (
    _,
    {
      extra: {
        controllerParams: {
          controllerConfig: {wixCodeApi},
        },
        api,
      },
    },
  ) => {
    const user = getCurrentUser(wixCodeApi)

    if (user?.loggedIn) {
      const {imageUrl} = await api.getUserProfileImage(user.id)
      return {user: getSerializableUser(user), imageUrl}
    } else {
      return null
    }
  },
)

const getSerializableUser = (user: IUser) => {
  const {id, loggedIn, role} = user

  return {
    id,
    loggedIn,
    role,
  }
}

export type PromptLogin = typeof promptLogin
export type Logout = typeof logout
export type LoadCurrentUser = typeof loadCurrentUser
