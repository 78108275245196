import {createAction} from '@reduxjs/toolkit'
import {ScheduleItem} from '../types'

export const toggleFiltersModal = createAction<void>('TOGGLE_FILTERS_MODAL')
export const toggleScheduleItemModal = createAction<ScheduleItem>('TOGGLE_SCHEDULE_ITEM_MODAL')
export const togglePersonalAgendaModal = createAction('TOGGLE_PERSONAL_AGENDA_MODAL')
export const toggleLoginDrawer = createAction('TOGGLE_LOGIN_DRAWER')

export type ToggleScheduleItemModal = typeof toggleScheduleItemModal
export type ToggleFiltersModal = typeof toggleFiltersModal
export type TogglePersonalAgendaModal = typeof togglePersonalAgendaModal
export type ToggleLoginDrawer = typeof toggleLoginDrawer
