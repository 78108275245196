import {createReducer} from '@reduxjs/toolkit'
import {
  toggleScheduleItemModal,
  toggleFiltersModal,
  togglePersonalAgendaModal,
  toggleLoginDrawer,
} from '../actions/modals'
import {createBookmark, deleteBookmark} from '../actions/schedule'
import {ScheduleItem} from '../types'

const scheduleItemModal: ScheduleItemModalState = {
  open: false,
  item: null,
}

const filtersModal: FiltersModalState = {
  open: false,
}

const personalAgendaModal: PersonalAgendaModalState = {
  open: false,
}

const loginDrawer: LoginDrawerState = {
  open: false,
}

const initialState: ModalsState = {
  scheduleItemModal,
  filtersModal,
  personalAgendaModal,
  loginDrawer,
}

export const modals = createReducer(initialState, builder => {
  builder
    .addCase(toggleScheduleItemModal, (state, action) => {
      state.scheduleItemModal = {
        open: !state.scheduleItemModal.open,
        item: action.payload,
      }
    })
    .addCase(createBookmark.fulfilled, ({scheduleItemModal: {open, item}}, action) => {
      if (open && item.id === action.meta.arg.itemId) {
        item.bookmarked = true
      }
    })
    .addCase(deleteBookmark.fulfilled, ({scheduleItemModal: {open, item}}, action) => {
      if (open && item.id === action.meta.arg.itemId) {
        item.bookmarked = false
      }
    })
    .addCase(toggleFiltersModal, state => {
      state.filtersModal = {
        open: !state.filtersModal.open,
      }
    })
    .addCase(togglePersonalAgendaModal, state => {
      state.personalAgendaModal = {
        open: !state.personalAgendaModal.open,
      }
    })
    .addCase(toggleLoginDrawer, state => {
      state.loginDrawer = {
        open: !state.loginDrawer.open,
      }
    })
})

interface FiltersModalState {
  open: boolean
}

interface ScheduleItemModalState {
  open: boolean
  item: ScheduleItem
}

interface PersonalAgendaModalState {
  open: boolean
}

interface LoginDrawerState {
  open: boolean
}
export interface ModalsState {
  scheduleItemModal: ScheduleItemModalState
  filtersModal: FiltersModalState
  personalAgendaModal: PersonalAgendaModalState
  loginDrawer: LoginDrawerState
}
